<template>
  <div>
    <v-card flat color="accent rounded-0">
      <v-row>
        <v-col md="2" v-if="batch">
          <box-regular
            :title="$t(`label.manufacturer`)"
            :manufacturer="batch.manufacturer"
          ></box-regular>
        </v-col>
        <v-col md="2" v-if="batch">
          <box-regular
            :title="$t(`label.reference`)"
            :reference="batch.reference"
          ></box-regular>
        </v-col>
        <v-col md="2" v-if="batch">
          <box-regular
            :title="$t(`batch-profile.description`)"
            :description="batch.description"
          ></box-regular>
        </v-col>
      </v-row>
    </v-card>

    <v-row v-if="batch" class="img-container flex justify-space-around">
      <v-col
        cols="2"
        v-for="item in batch.images"
        :key="item.description"
        class="img-col mb-n4"
      >
        <img
          :src="baseUrl + item.image"
          :alt="item.description"
          class="batch-image"
        />
      </v-col>
    </v-row>

    <section v-if="batch" class="mt-n4">
      <v-data-table
        class="custom-table ma-5 mt-16"
        :headers="headers"
        :items="batch.stages"
        :items-per-page="limit"
        :page.sync="page"
        :server-items-length="batch.stages.length"
        @update:page="paginate"
        :footer-props="{
          itemsPerPageText: '',
          itemsPerPageOptions: [],
        }"
        :hide-default-footer="true"
        dense
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-btn text small>
            <img
              style="cursor: pointer"
              src="@/assets/images/Icon-download.svg"
              @click="downloadCsv(item.id)"
            />
          </v-btn>
        </template>
      </v-data-table>

      <v-row class="d-flex">
        <v-col class="mx-auto mb-6" cols="4" v-if="batch.stages.length > limit">
          <v-container class="w-100">
            <v-pagination
              v-model="page"
              :length="Math.ceil(batch.stages.length / limit)"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment/dist/moment";
import i18n from "@/i18n";

export default {
  name: "batch-profile",
  async mounted() {
    this.retriveBatch({ batch_number: this.$route.params.id });
  },
  data() {
    return {
      username: "",
      reader: "",
      startDate: null,
      endDate: null,
      page: 1,
      limit: 10,
      headers: [
        { text: i18n.t("label.state"), value: "stageType" },
        { text: i18n.t("label.start-date"), value: "start_date" },
        { text: i18n.t("label.end-date"), value: "end_date" },
        { text: i18n.t("label.tag-start"), value: "tags_start" },
        { text: i18n.t("label.tag-end"), value: "tag_end" },
        { text: i18n.t("label.action"), value: "action" },
      ],
    };
  },
  methods: {
    ...mapActions({
      retriveBatch: "batchList/retriveBatch",
      downloadProfileCsv: "batchList/downloadProfileCsv",
    }),

    paginate(val) {
      this.page = val - 1;
      // this.retriveBatch({
      //   limit: this.limit,
      //   offset: this.page * this.limit,
      // });
      this.page = val;
    },

    downloadCsv(id) {
      const payload = { batch: this.$route.params.id, id };
      if (payload) {
        this.downloadProfileCsv(payload);
      }
    },

    formattedDate(date) {
      return date ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "";
    },
  },
  computed: {
    ...mapGetters({
      batch: "batchList/getBatch",
    }),
    baseUrl() {
      return process.env.VUE_APP_API_BASE;
    },
  },
};
</script>

<style></style>
